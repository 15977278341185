<template>
	<div id="acknowledgements" class="container page">
		<h3><mark>Acknowledgements</mark></h3>
		<p>We are grateful to all our colleagues, clients and well-wishers who have been a part of our journey over the last 30 years. They have helped us forge our understanding of pedagogy and working with the system by supporting us in various
			endeavours offering sound advice, help and opportunities at different steps.</p>

		<p>This particular manifestation of Chachi, the activity generator, has been made possible by these wonderful people –</p>
		<ul>
			<li>Chhaya Upadhyay, for translating the content to Gujarati. You can find out more about them <a href="http://interact-6aya.blogspot.com/">here.</a></li>
			<li>Savita Damle, for translating the content to Marathi.</li>
			<li>Smruti Ranjan Jena, for translating the content to Odia. You can find out more about them <a href="https://www.facebook.com/smruti.jena">here.</a></li>
			<li>Sriram Naganathan, for translating the content to Tamil.</li>
			<li>Malavika Goyal, for providing inputs for the content on <a href="/en/tags/equity">equity.</a> You can find out more about them <a href="https://www.linkedin.com/in/malavika-goyal-b627a9141/">here.</a></li>
			<li>Angeline Bhavya Irudhayaraj, for providing amazing inputs for improving the site's UI/UX. You can find out more about them <a href="https://www.linkedin.com/in/angelinebhavya/">here.</a></li>
		</ul>
		<p>If you would like to help with translations <a href="mailto:contact@chachi.app?Subject=Translations"> please get in touch</a>!</p>

		<p>We wouldn’t miss this chance to thank some of the organisational partners who have collaborated with us over the years.</p>

		<p>In addition, there are the <strong>governments and SCERTs of over 25 states and UTs of India</strong> whom we owe a great deal for providing us with the space to experiment and hone our craft.</p>

		<p>The numbers are too many so please forgive us if we missed mentioning you. <a href="https://chachi.app/contact">Send us a message</a> and we can catch up. We will add you to this list too!</p>
	</div>

</template>

<script>
export default {
	name: 'Acknowledgements',
}
</script>

<style scoped>
#acknowledgements {
text-align: left;
}
.card-img-top {
	width: 100%;
	border-top-left-radius: calc(.25rem - 1px);
	border-top-right-radius: calc(.25rem - 1px);
}

.partners-card {
	height: 120px;
	width: 120px;
	margin-right: 10px;
	margin-bottom: 10px;
	border: 1px black solid;
	box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.05);
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.card-image {
	transition: .2s;
	transform: scale(0.8);
}

.card-image:hover {
	transform: scale(0.9);
}


@media screen and (max-width: 667px) {
	.partners-card {
		height: 80px;
		width: 80px;
	}

	img.card-image {
		width: 80px;
	}
    }
</style>
